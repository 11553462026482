import React from 'react'
export default function Home({ children }: { children: React.ReactNode }) {
  return (
    <>
      <style>
        {`
    @import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

    @font-face {
      font-family: HeliosExt;
      src: url(/assets/font/HeliosExt.otf);
    }
    @font-face {
      font-family: Roobert;
      src: url(/assets/font/Roobert-Regular.ttf);
    }
    
    *,
    *::after,
    *::before {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    
  
    body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #131825;
      overflow-x: hidden;
    }
    
    img {
      pointer-events: none;
    }
    
    span {
      background: linear-gradient(135deg, #6df2d2 0%, #13ff6f 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    
    .title {
      font-family: "HeliosExt";
      font-style: normal;
      font-weight: 400;
      color: #fff;
    }
    
    .description {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
    }
    
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
    }
    .nav-logo {
      width: 25%;
      height: 30px;
    }
    .nav-logo img {
      height: 100%;
    }
    
    .nav-links .language-btn {
      padding-left: 10px;
      padding-right: 10px;
    }
    
    .nav-links > a,
    .nav-links > div {
      margin-left: 45px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      text-decoration: none;
      display: inline-block;
    }
    
    .nav-links > a:hover,
    .nav-links > div:hover {
      color: #26c879;
      cursor: pointer;
    }
    
    .nav .menu-btn {
      display: none;
      padding: 0;
    }
    
    /*mobile-nav*/
    
    .container > .mobile-nav {
      padding: 12px;
      width: 100%;
      display: none;
    }
    
    ul.mobile-links{
      list-style-type: none;
      width: 100%;
    }
    
    ul.mobile-links li{
      width: 100%;
      margin-top: 30px;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      color: #fff;
    }
    
    ul.mobile-links li a{
      color: #fff;
      text-decoration: none;
    }
    
    button {
      height: 36px;
      padding: 0 20px;
      background: #131825;
      color: #fff;
      border: 1px solid rgba(109, 242, 210, 0.15);
      font-size: 12px;
      position: relative;
    }
    
    button:hover {
      cursor: pointer;
      border: 1px solid rgba(109, 242, 211, 0.329);
    }
    
    button::before {
      background-image: url("assets/Group\ 54.svg");
      background-repeat: no-repeat;
      background-size: 29%;
      content: "";
      position: absolute;
      display: inline-block;
      width: 20px;
      height: 50px;
      top: 0;
      left: 0;
    }
    button::after {
      background-image: url("assets/Group\ 55.svg");
      background-repeat: no-repeat;
      background-size: 29%;
      content: "";
      position: absolute;
      display: inline-block;
      width: 20px;
      height: 50px;
      top: 0px;
      right: -15px;
    }
    
    button:hover {
      border: 1px solid rgba(109, 242, 158, 0.329);
      filter: brightness(140%);
    }
    
    .dropdown-content {
      display: none;
    }
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 950px;
      padding: 0 0px;
    }
    
    .container > div {
      width: 100%;
      display: flex;
    }
    
    .banner-text {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 18px;
      padding:5px;
      margin-top: 75px;
    }
    
    .banner-text1 {
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #ffffff;
    }
    
    .banner-text2 {
      font-size: 30px;
      line-height: 30px;
      color: #ffffff;
    }
    
    .banner-text3 {
      max-width: 400px;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      color: #939db8;
    }
    
    .container > .banner-img {
      z-index: -1;
      background: #131825;
      position: relative;
      padding-top: 155px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      width: 100vw;
      border-bottom: 1px solid #a9b1cb18;
    }
    .container > .banner-img2 {
      width: 50%;
      background-image: linear-gradient(135deg, #6df2d2 0%, #13ff6f 100%);
      opacity: 0.3;
      filter: blur(140px);
      display: flex;
      justify-content: center;
      position: relative;
      align-items: center;
      height: 150px;
      z-index: -2;
    }
    
    .container > .banner-img > img {
      width: 20%;
      position: absolute;
    }
    
    .container > .banner-img3 {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -150px;
      width: 50%;
    }
    
    .container > .banner-img3 img {
      width: 100%;
    }
    
    .section1 {
      margin-top: 75px;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    
    .section1-item {
      background: #0e131f;
      display: flex;
      padding: 24px;
      position: relative;
    }
    
    .section1-item:first-child {
      background-image: url("assets/section1-bg1.svg");
      background-repeat: no-repeat;
      background-position: 100% 0%;
      background-size: auto 120%;
    }
    .section1-item:nth-child(2) {
      background-image: url("assets/section1-bg2.svg");
      background-repeat: no-repeat;
      background-position: -20%;
    }
    
    .section1-item:nth-child(even) {
      flex-direction: row-reverse;
    }
    
    .section1-item > div,
    .section2-item > div {
      width: 50%;
      padding: 48px;
    }
    .section1-item-img img,
    .section2-item-img img {
      width: 100%;
    }
    
    .section1-item-text,
    .section2-item-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 18px;
      padding: 0 24px;
    }
    
    .container .section2 {
      flex-direction: column;
      background: #f3f6f8;
      width: 100vw;
      position: relative;
      padding: 220px 0;
      margin-top: -200px;
      z-index: -1;
    }
    
    .container .section2::after {
      content: "";
      width: 100vw;
      height: 500px;
      bottom: -500px;
      background-image: url("assets/background-grid.svg");
      background-size: 100%;
      background-position: top;
      background-repeat: no-repeat;
      position: absolute;
    }
    .container #section12 .section1-item:first-child::after {
      content: "";
      width: 30vw;
      height: 400px;
      left: -100px;
      right: -200px;
      background: linear-gradient(135deg, #6df2d2 0%, #13ff6f 100%);
      opacity: 0.3;
      filter: blur(140px);
      background-size: 100%;
      background-position: top;
      background-repeat: no-repeat;
      position: absolute;
      z-index: -1;
    }
    .container #section12 .section1-item:nth-child(2)::after {
      content: "";
      width: 40vw;
      height: 400px;
      top: -100px;
      right: -200px;
      background: linear-gradient(135deg, #6df2d2 0%, #13ff6f 100%);
      opacity: 0.3;
      filter: blur(140px);
      background-size: 100%;
      background-position: top;
      background-repeat: no-repeat;
      position: absolute;
      z-index: -1;
    }
    
    .section2-item {
      display: flex;
      max-width: 950px;
      margin: 0 auto;
    }
    .section2-item:nth-child(even) {
      flex-direction: row-reverse;
    }
    
    #section12 {
      margin-top: -160px;
    }
    
    .container > .section4 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      margin-top: 90px;
      gap: 50px;
      padding: 50px 0;
    }
    
    .section4-item-img {
      width: 60px;
      height: 60px;
    }
    
    .section4-item-img img {
      width: 100%;
    }
    
    .section4-item {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
    
    .section4-item:first-child {
      grid-area: 1 / 1 / 2 / 2;
    }
    .section4-item:nth-child(2) {
      grid-area: 1 / 2 / 2 / 4;
    }
    .section4-item:nth-child(3) {
      grid-area: 2 / 1 / 3 / 2;
    }
    .section4-item:nth-child(4) {
      grid-area: 2 / 2 / 3 / 3;
    }
    .section4-item:nth-child(5) {
      grid-area: 2 / 3 / 3 / 4;
    }
    
    footer {
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      padding: 40px 0;
      border-top: 1px solid #a9b1cb3d;
      text-align: center;
      width: 100%;
      max-width: 1000px;
      background: #131825;
      z-index: 1;
    }
    .footer-logo {
      width: 25%;
      margin: 0 auto;
    }
    .footer-logo img {
      width: 100%;
      max-width: 200px;
    }
    
    .footer-text1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      background: linear-gradient(135deg, #6df2d2 0%, #13ff6f 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-top: 20px;
    }
    
    .footer-text2 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 24px;
      color: #a9b1cb;
      margin-top: 40px;
    }
    
    .section1-item-text1,
    .section2-item-text1 {
      font-family: "HeliosExt";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: #ffffff;
    }
    
    .section1-item-text2,
    .section2-item-text2 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #939db8;
    }
    
    .section2-item-text .section2-item-text1 {
      color: #29363d;
    }
    
    .section4-item-text1 {
      font-family: "HeliosExt";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }
    
    .section4-item-text2 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #939db8;
    }
    
    .background2 {
      width: 100%;
      height: 100%;
      display: flex;
      position: absolute;
      top: 0;
      z-index: -1;
    }
    
    .background2 > div {
      border-left: 1px solid #a9b1cb18;
      border-right: 1px solid #a9b1cb18;
      height: 100%;
      width: 25%;
    }
    
    @media screen and (max-width: 800px) {
      .section1-item:first-child,
      .section2-item:first-child {
        flex-direction: column;
        background-position: bottom;
      }
      .section1-item:nth-child(2),
      .section2-item:nth-child(even) {
        flex-direction: column;
        background-position: bottom;
      }
    
      .section1-item > div,
      .section2-item > div {
        width: 100%;
        padding: 24px;
      }
    
      .section2-item > .section2-item-img,
      .section1-item > .section1-item-img {
        max-width: 600px;
        margin: 0 auto;
      }
      .container > .banner-img > img {
        width: 30%;
      }
      .container > .banner-img3 {
        width: 60%;
      }
    
      .background2 {
        display: none;
      }
      .footer-logo {
        width: 50%;
        margin: 0 auto;
      }
      .container > .section4 {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        padding: 0 24px;
        gap: 25px;
      }
    
      .section4-item:first-child, .section4-item:nth-child(2) ,.section4-item:nth-child(3) ,.section4-item:nth-child(5) ,.section4-item:nth-child(4)  {
        grid-area: auto;
      }
    
      .nav-links{
        display: none;
      }
    
      .nav .menu-btn {
        display: block;
        padding: 0 12px;
      }
      
    }
    
    
    `}
      </style>
      <div className="container">
        <div className="banner-text" style={{
          marginTop: '0px'
        }}>
          <div className="banner-text1">
            Helpseed Swap is an Automated Market Maker (AMM)
          </div>
          <div className="banner-text2 title">
            Exchange is at the <span>heart of Helpseed Swap </span>
          </div>
          <div className="banner-text3 description">
            Helpseed Swap is the leading AMM on the HelpSeed Chain, and one of the most
            popular Decentralized Exchange (DEX)! The Helpseed Swap Exchange offers
            several features that support decentralized trading:
          </div>
        </div>
        <div className="banner-img">
          <img src="assets/section1-img.svg" alt="" />
        </div>
        <div className="banner-img2" />
        <div className="banner-img3">
          <img src="assets/section1-img-2.svg" alt="" />
        </div>
        <div className="section1">
          <div className="section1-item">
            <div className="section1-item-text">
              <div className="section1-item-text1">Swapping/Trading</div>
              <div className="section1-item-text2">
                PancakeSwap lets users trade without the need to go through a
                Centralized Exchange. Everything you do on Helpseed Swap is routed
                directly through your own wallet—no need to trust someone else
                with your coins!
              </div>
            </div>
            <div className="section1-item-img">
              <img src="assets/item1-img.svg" alt="" />
            </div>
          </div>
          <div className="section1-item">
            <div className="section1-item-text">
              <div className="section1-item-text1">Liquidity Pools</div>
              <div className="section1-item-text2">
                You can only swap tokens on Helpseed Swap if there is enough liquidity
                for those tokens. If nobody has added much liquidity for the token
                or tokens you want to swap, it will be difficult, expensive, or
                impossible to do so. Providing liquidity will get you LP Tokens,
                which will earn you rewards in the form of trading fees for making
                sure there's always liquidity for the exchange to use.
              </div>
            </div>
            <div className="section1-item-img">
              <img src="assets/item2-img.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="section2-item">
            <div className="section2-item-text">
              <div className="section2-item-text1">Yield Farming</div>
              <div className="section2-item-text2">
                Yield farming lets users that are providing liquidity earn HELPS
                rewards by locking their LP tokens into a smart contract. The
                incentive is to balance out the risk of impermanent loss that
                comes along with locking in your liquidity.
              </div>
            </div>
            <div className="section2-item-img">
              <img src="assets/yield-farming.svg" alt="" />
            </div>
          </div>
          <div className="section2-item">
            <div className="section2-item-text">
              <div className="section2-item-text1">Initial Farm Offerings (IFOs)</div>
              <div className="section2-item-text2">
                An Initial Farm Offering is an event that lets users buy into a
                limited-time offer to purchase new tokens. The IFO price is
                usually very generous.
              </div>
            </div>
            <div className="section2-item-img">
              <img src="assets/ifos.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="section1" id="section12">
          <div className="section1-item">
            <div className="section1-item-text">
              <div className="section1-item-text1">Low fees</div>
              <div className="section1-item-text2">
                Why pay more? Helpseed Swap runs on HelpSeed Chain, a blockchain with
                much lower transaction costs than Ethereum or Bitcoin. Trading
                fees are lower than other top decentralized exchanges too, so
                that's a double win for you!
              </div>
            </div>
            <div className="section1-item-img">
              <img src="assets/low-fees.svg" alt="" />
            </div>
          </div>
          <div className="section1-item">
            <div className="section1-item-text">
              <div className="section1-item-text1">Decentralized</div>
              <div className="section1-item-text2">
                Trade directly from your wallet app. Unlike centralized exchanges
                like Binance or Coinbase, Helpseed Swap doesn’t hold your funds when you
                trade: you have 100% ownership of your own crypto.
              </div>
            </div>
            <div className="section1-item-img">
              <img src="assets/decentralized.png" alt="" />
            </div>
          </div>
        </div>
        <div className="section4">
          <div className="section4-item">
            <div className="section4-item-img">
              <img src="assets/oneswap-protokol.svg" alt="" />
            </div>
            <div className="section4-item-text1">Helpseed Swap Protocol</div>
            <div className="section4-item-text2">
              Swap, earn, and build on the leading decentralized crypto trading
              protocol.
            </div>
          </div>
          <div className="section4-item">
            <div className="section4-item-img">
              <img src="assets/oneswap-ecosystem.svg" alt="" />
            </div>
            <div className="section4-item-text1">Helpseed Swap Ecosystem</div>
            <div className="section4-item-text2">
              A growing network of DeFi Apps. Developers, traders, and liquidity
              providers participate together in a financial marketplace that is
              open and accessible to all.
            </div>
          </div>
          <div className="section4-item">
            <div className="section4-item-img">
              <img src="assets/trade.svg" alt="" />
            </div>
            <div className="section4-item-text1">Trade</div>
            <div className="section4-item-text2">
              Swap, earn, and build on the leading decentralized crypto trading
              protocol.
            </div>
          </div>
          <div className="section4-item">
            <div className="section4-item-img">
              <img src="assets/create.svg" alt="" />
            </div>
            <div className="section4-item-text1">Create</div>
            <div className="section4-item-text2">
              Swap, earn, and build on the leading decentralized crypto trading
              protocol.
            </div>
          </div>
          <div className="section4-item">
            <div className="section4-item-img">
              <img src="assets/market-make.svg" alt="" />
            </div>
            <div className="section4-item-text1">Market Maker</div>
            <div className="section4-item-text2">
              Swap, earn, and build on the leading decentralized crypto trading
              protocol.
            </div>
          </div>
          <div className="background2">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <footer>
          <div className="footer-logo">
            <img src="assets/images/logoo.png" alt="" />
          </div>
          <div className="footer-text1">
            provides a smooth and reliable transfer of crypto assets
          </div>
          <div className="footer-text2">2021 © HelpSeed</div>
        </footer>
      </div>


    </>
  )
}
